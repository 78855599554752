.bookmark-button {
  float: right;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bookmark-button:hover {
  background-color: #e0e0e0;
}

.bookmark-button svg {
  margin-right: 5px;
}