.searchbar {
  margin-top: 10px;
  margin-bottom: 10px;
}

.search-form {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input {
  width: 70%;
  height: 25px;
  margin-right: 5px;
}

.search-sort {
  height: 30px;
  margin-right: 5px;
}

.search-button {
  height: 30px;
}
