.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
}

.header_left {
  text-align: left;
}

.header_center {
  text-align: center;
  font-size: 1.5em; 
  flex-grow: 1; /* 가운데 텍스트가 가변 폭을 가지도록 설정 */
}

.header_right {
  text-align: right;
}

.header_login_button, 
.header_logout_button {
  background-color: transparent;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 24px; 
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.header_login_button:hover 
.header_logout_button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
