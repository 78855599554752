.question-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}

.question-card h3 {
  width: 90%;
  margin-top: 5px;
  margin-left: 15px;
  margin-bottom: 0px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.question-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date {
  margin-right: auto;
}

.counts {
  display: flex;
  float: right;
  margin-right: 20px;
}

.counts p {
  margin-left: 10px;
}

.comments-count,
.views {
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  float: right;
}

.comments-count svg {
  margin-right: 1px;
  vertical-align: middle;
  font-size: 16px; 
}

.views svg {
  margin-right: 1px;
  vertical-align: middle;
  font-size: 18px;
}