.profile-container {
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.profile-header {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0px;
}

.profile-header p {
  font-size: 1.8em;
  color: #f39c12;
}

.profile-header h4 {
  font-size: 1.2em;
  color: #333;
}

.profile-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.profile-tabs button {
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s;
}

.profile-tabs button:hover,
.profile-tabs button.active {
  border-bottom: 2px solid #3498db;
  color: #3498db;
}

.profile-content {
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
}

.profile-content p {
  text-align: center;
  color: #888;
}
