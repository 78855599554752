#post-question {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

#post-question-header {
  text-align: center;
  margin-bottom: 10px;
}

#post-question-content {
  width: 100%;
  max-width: 600px;
}

#post-question-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#post-question-form .form-group-title {
  display: flex;
  align-items: center;
  width: 100%;
}

#post-question-form label {
  font-size: 1.5em;
  margin-right: 10px;
}

#post-question-form input[type="text"],
#post-question-form textarea {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  box-sizing: border-box;
  width: 100%;
}

#post-question-form textarea {
  min-height: 300px;
}

#post-question-form input[type="text"]:focus,
#post-question-form textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

#post-question-form button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #070707;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#post-question-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

span {
  color: red;
  font-size: 0.9em;
}

#question-content-textarea {
  min-height: 300px;
}
