#login-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

#google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0; /* 연한 회색 */
  color: black; /* 흰색 배경에 검은색 텍스트가 가시적입니다 */
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

#google-login-button:hover {
  background-color: #d9d9d9; /* 연한 회색의 더 진한 버전 */
}

#google-login-button svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
