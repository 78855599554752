.question-detail {
  margin: 0 auto;
  padding: 10px;
  max-width: 800px; 
}

.question-detail-header {
  margin-bottom: 20px;
}

.question-title {
  margin-bottom: 5px;
}

.question-createdAt,
.question-views, 
.question-writer {
  display: inline-block;
  font-size: 14px;
}

.question-createdAt,
.question-views {
  float: left;
}

.question-views {
  margin-left: 30px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.question-views-icon {
  margin-right: 4px;
  font-size: 18px;
}

.question-writer {
  float: right;
}

.question-detail-content {
  padding: 10px;
  margin-top: 40px;
  text-align: center;
}

.question-content {
  border: 1px solid #ccc;
  padding: 10px; 
  min-height: 300px; 
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-detail-footer {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  margin-top: 1px;
  justify-content: flex-end;
  gap: 10px;
}

.edit-question-button,
.delete-question-button {
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 5px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment-button,
.bookmark-button {
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-question-button:hover,
.delete-question-button:hover,
.comment-button:hover,
.bookmark-button:hover {
  background-color: #e0e0e0;
}

.edit-question-button svg,
.delete-question-button svg,
.comment-button svg,
.bookmark-button svg {
  margin-right: 5px;
}

.comment-modal-container {
  width: 400px;
  height: 600px;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.comment-modal-header {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.comment-count {
  font-size: 1.15rem;
  font-weight: bold;
}

.comment-modal-close-button {
  position: absolute;
  right: 1px;
  top: 60%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.comment-modal-content {
  flex: 1;
  overflow-y: auto;
  margin-top: 15px;
  width: 90%;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}

.comment-modal-content::-webkit-scrollbar {
  display: none;
}

.comment-modal-footer {
  width: 90%;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.write-comment-form {
  width: 100%;
  display: flex;
  align-items: center;
}

.write-comment-form label {
  margin-right: 10px;
  font-size: 1.5rem;
}

.write-comment-form input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.write-comment-form button {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.write-comment-form button:disabled {
  background: #ccc;
}

.write-comment-form span {
  color: red;
  font-size: 0.8rem;
  margin-left: 10px;
}


